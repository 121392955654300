import { defineStore } from 'pinia'
import { ref } from 'vue'
import {
  checkUser,
  loginUser,
} from '../api/auth'
import { useInstallationStore } from './installation'
import { useNotificationStore } from './notification'
import Cookies from 'js-cookie'
import metrics from '../metrics'

export const useUserStore = defineStore('user', () => {

  const installation = useInstallationStore()
  const notification = useNotificationStore()
  const username = ref(null)
  const admin = ref(null)
  const staff = ref(null)
  const vault = ref(null)
  const model_permissions = ref(new Set())
  const connected = ref(null)
  const organization = ref(null)

  function updateUser(data) {
    // if (!data) {
    //   connected.value = false
    //   return
    // }
    username.value = data.username
    staff.value = data.staff
    admin.value = data.admin
    vault.value = data.vault
    organization.value = data.organization
    model_permissions.value = new Set(
      Object.entries(data.model_permissions || {})
        .filter(([, v]) => v)
        .map(([p,]) => p)
    )
    connected.value = data.connected
  }

  async function checkUserAuth() {
    const [err, data] = await checkUser()

    updateUser(data)
    installation.updateInstallation(data)
    metrics.setLoggedIn(data && data.connected)

    if (data && data.notify_release) {
      if (Cookies.get('glyue-release') === data.git.version) {
        return [err, data]
      }
      Cookies.set('glyue-release', data.git.version, { expires: 90})
      let version_str = ` for ${data.git.version}` ?? ''
      setTimeout(() => {
        notification.addNotification({
          msg: `Check out the new release notes${version_str}`,
          link: { href: 'https://alphapack.atlassian.net/wiki/spaces/PUB/pages/608174097', text: 'Glyue Release Notes' },
          type: 'info',
          timeout: 50000,
          showClose: true,
        })
      }, 5000)
    }
    updateUser(data)
    installation.updateInstallation(data)
    return [err, data]
  }

  async function login(username, password) {
    const [err, data] = await loginUser(username, password)
    if (err) {
      return [err]
    }
    updateUser(data)
    return [err, data]
  }

  function hasPermission(permission) {
    return model_permissions.value.has(permission)
  }

  return {
    connected,
    username,
    admin,
    staff,
    vault,
    model_permissions,
    organization,
    login,
    updateUser,
    checkUserAuth,
    hasPermission,
  }
})
