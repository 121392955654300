import axios from 'axios'
import { getCookieValue } from '../views/common'
import { instance } from '.'

export const getUsers = async (integrations, searchtext, limit) => {
  let params =  {}
  if (searchtext) { params.searchtext = searchtext }
  if (limit) { params.limit = limit }
  if (integrations) { params.integrations = integrations }

  try {
    const response = await instance.get(
      '/lists/users',
      { params: params }
    )
    return [ null, response.data.users ]
  } catch (err) {
    console.error(err)
    return [err]
  }
}

export const getUserPreferences = async () => {
  try {
    let response = await instance.get(
      `/users/preferences`,
    )
    return [null, response.data]
  } catch (err) {
    console.error(err)
    return [err]
  }
}

export const updateUserPreference = async (prefName, prefKey) => {
  try {
    let response = await instance.patch(
      `/users/preferences`,
      { [prefName]: prefKey},
    )
    return [null, response.data]
  } catch (err) {
    console.error(err)
    return [err]
  }
}

export const getUserNotifications = async (showRead, timestamp = null) => {
  let params = {}
  if (showRead) { params.showRead = true }
  if (timestamp) { params.timestamp = timestamp }
  try {
    let response = await instance.get(
      '/polls/notifications',
      {
        params: params,
      })
    return [null, response.data.notifications ?? []]
  } catch (err) {
    console.error(err)
    return [err]
  }
}

export const updateUserNotificationById = async (id, updateProperties = {}) => {
  try {
    await axios({
      url: `/integrations/services/notifications/${id}`,
      method: 'PATCH',
      credentials: 'include',
      headers: {
        "X-CSRFToken": getCookieValue('csrftoken')
      },
      data: {
        ...updateProperties
      }
    })
    return [null]
  } catch (err) {
    return [err]
  }
}

export const getUserOrganizations = async () => {
  try {
    const data = await axios({
      url: "/account/organizations/",
      method: "GET",
      credentials: "include",
      headers: {
        "X-CSRFToken": getCookieValue("csrftoken"),
      },
    })
    return [ null, data ]
  } catch (err) {
    return [ err ]
  }
}

export const getUserGroups = async() => {
  try {
    const data = await axios({
      url: "/account/groups/",
      method: "GET",
      credentials: "include",
      headers: {
        "X-CSRFToken": getCookieValue("csrftoken"),
      },
    })
    return [ null, data ]
  } catch (err) {
    return [ err ]
  }
}
