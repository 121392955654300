<template>
  <v-navigation-drawer
    v-if="!isEtl"
    :expand-on-hover="true"
    permanent
    data-cy="app-navigation-bar"
    :rail="true"
    :width="null"
  >
    <v-list
      v-model="currentPage"
      density="compact"
    >
      <v-list-item
        v-for="item in userNavigationLinks"
        :key="item.text"
        data-cy="app-nav-link"
        :to="item.ref"
        :value="item.ref"
        :prepend-icon="item.icon"
        :title="item.text"
      />
    </v-list>
  </v-navigation-drawer>
  <v-navigation-drawer
    v-else
    class="etl-nav"
    permanent
    width="196"
    style="border: none"
  >
    <v-list
      :selected="[etlRoute]"
      nav
    >
      <v-list-item
        v-for="(etlNav, idx) in etlNavs"
        :key="idx"
        :to="etlNav.ref"
        :value="etlNav.ref"
        :title="etlNav.text"
        :prepend-icon="etlNav.icon"
      />
    </v-list>
  </v-navigation-drawer>
</template>

<script setup>
import { onMounted, ref, computed, inject } from 'vue'
import { useRoute, useRouter } from "vue-router"
import { useUserStore } from "@/stores/user"
import { useInstallationStore } from "@/stores/installation"

const router = useRouter()
const route = useRoute()
const keybindAdd = inject("glyue:keybindAdd")
const userStore = useUserStore()
const installationStore = useInstallationStore()
const currentPage = ref(-1)
const isEtl = computed(() => router.currentRoute.value.path.startsWith("/etl"))
const glyueNav = ref([
  { icon: "mdi-view-dashboard", text: "Dashboard", ref: "/dashboard" },
  { icon: "mdi-bookmark-multiple", text: "Bookmarks", ref: "/bookmarks" },
  { icon: "mdi-history", text: "Run History", ref: "/history" },
  { icon: "mdi-wrench", text: "Build", ref: "/build" },
  { icon: "mdi-api", text: "Swagger API", ref: "/swagger" },
  { icon: "mdi-sitemap", text: "Frontends", ref: "/frontends", permissions: ["view_frontend"] },
  { icon: "mdi-safe-square-outline", text: "Vault", ref: "/vault", vault: true },
  { icon: "mdi-calendar-clock", text: "Schedule", ref: "/schedules/dashboard" },
  { icon: "mdi-folder-move", text: "Migrate", ref: "/migrate", staff: true },
  { icon: "mdi-book-open-page-variant-outline", text: "Catalog", ref: "/catalog", catalogEnabled: true },
  { icon: "mdi-file-compare", text: "View Changes", ref: "/diff", staff: true },
  { icon: "mdi-text-long", text: "Log", ref: "/log", admin: true },
  { icon: "mdi-vpn", text: "OpenVPN", ref: "/openvpn", admin: true },
  { icon: "mdi-account-plus", text: "Invite", ref: "/invite", staff: true },
])


const userNavigationLinks = computed(() => glyueNav.value.filter(v => {
  const adminOkay = !v.admin || (userStore.admin)
  const staffOkay = !v.staff || (userStore.staff || userStore.admin)
  const vaultOkay = !v.vault || userStore.vault
  const catalogOkay = !v.catalogEnabled || installationStore.catalogEnabled
  const permissionsOkay = !v.permissions || v.permissions.every((permission) => userStore.model_permissions.has(permission))

  return adminOkay && vaultOkay && catalogOkay && staffOkay && permissionsOkay
}))

const etlNavs = ref([
  {
    icon: "mdi-plus",
    text: "New Workflow",
    ref: "/etl/workflows/create",
  },
  {
    icon: "mdi-sitemap",
    text: "My Workflows",
    ref: "/etl/workflows",
    startsWith: true,
  },
  {
    icon: "mdi-connection",
    text: "Data Connections",
    ref: "/etl/connections",
    startsWith: true,
  },
  // {
  //   icon: "mdi-format-list-bulleted",
  //   text: "Value Mappings",
  //   ref: "/etl/valuemaps",
  // },
  {
    icon: "mdi-account-plus",
    text: "Invite",
    ref: "/etl/invite",
  }
])

const etlRoute = computed(() => {
  const path = route.path

  const nav = etlNavs.value.find((nav) => {
    if (nav.startsWith) {
      return path.startsWith(nav.ref)
    } else {
      return path === nav.ref
    }
  })

  return nav?.ref ?? "/etc/workflows"
})

onMounted(() => {
  glyueNav.value.forEach((item) => {
    const page = item.ref.substring(1)

    keybindAdd(
      `nav-${page}`,
      () => router.push(item.ref),
      { metric: `nav/${page}` }
    )
  })

  keybindAdd(
    "nav-preferences",
    () => router.push("/preferences"),
    { metric: "nav/preferences" }
  )
})
</script>
