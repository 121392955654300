<template>
  <v-banner
    v-if="notificationStore.banners.length"
    v-bind="props"
    class="glyue-banner"
    rounded
  >
    <v-sheet
      elevation="1"
      color="white"
    >
      {{ notificationStore.banners[0].text }}
    </v-sheet>
    <template #actions="{ dismiss }">
      <g-btn
        variant="text"
        color="primary"
        @click="dismiss(); notificationStore.removeBanner()"
      >
        <v-icon>mdi-close</v-icon>
        Dismiss
      </g-btn>
    </template>
  </v-banner>
</template>
<script setup>

import { useNotificationStore } from "../../stores/notification"
const notificationStore = useNotificationStore()
</script>
