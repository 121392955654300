<template>
  <v-menu :close-on-content-click="false">
    <template #activator="{ props }">
      <g-btn metric="app/notifications" color="grey" v-bind="props" icon>
        <v-badge v-if="unreadNotificationCount > 0" location="top right" data-cy="notification-badge" color="error"
          :content="unreadNotificationCount" :value="unreadNotificationCount">
          <v-icon>
            mdi-bell
          </v-icon>
        </v-badge>
        <v-icon v-else>
          mdi-bell
        </v-icon>
      </g-btn>
    </template>
    <v-card min-width="540px" max-width="540px">
      <v-banner v-if="notificationMessage" data-cy="notification-error" class="text--white" color="error" single-line>
        <div class="pl-1 text-white">
          {{ notificationMessage }}
        </div>
      </v-banner>
      <v-list>
        <v-list-item title="Notifications">
          <template #append>
            <v-switch v-model="showRead" density="compact" data-cy="notification-switch"
              :label="showRead ? 'Hide Unread' : 'Show Read'" hide-details />
          </template>
        </v-list-item>
      </v-list>

      <v-divider />
      <v-list v-if="notifications.length > 0" density="compact">
        <notification-list-item v-for="notification of notifications" :key="notification.id"
          :notification="notification" :is-active="notification.id === selectedNotification"
          @update:notification="updateNotification" @select:notification="selectNotification" />
      </v-list>
      <v-list-item v-else>
        <v-list-item-title>No notifications</v-list-item-title>
      </v-list-item>
    </v-card>
  </v-menu>
</template>
<script>
import { getUserNotifications } from '../../api/user'
import NotificationListItem from './NotificationListItem.vue'
import { dateToUnixTimeStamp } from '../../views/common'
export default {
  components: {
    NotificationListItem
  },
  data() {
    return {
      notifications: [],
      notificationMessage: "",
      showRead: false,
      selectedNotification: null,
      polls: {
        isActive: true,
        notificationIntervalId: null,
        pollTimeout: 45000,
        retryCount: 0,
        sessionActive: true,
      }
    }
  },
  computed: {
    unreadNotificationCount: function () {
      return this.notifications.filter(x => !x.read_at).length
    },
    lastTimestamp: function () {
      if (this.notifications.length === 0) return null
      else if (this.notifications.length === 1) {
        return dateToUnixTimeStamp(new Date(this.notifications[0].notification.created_at))
      }
      return dateToUnixTimeStamp(this.notifications.reduce((acc, curr) => {
        return acc > new Date(curr.notification.created_at) ? acc : new Date(curr.notification.created_at)
      }))
    }
  },
  watch: {
    showRead: function () {
      this.loadNotifications()
    },
  },
  mounted() {
    document.addEventListener("visibilitychange", this.notificationVisibilityChange, false)
    this.startNotificationPoll()
  },
  methods: {
    notificationVisibilityChange() {
      if (document.visibilityState === 'hidden') {
        this.stopPolling()
      } else if (document.visibilityState === 'visible' && !this.polls.isActive && this.polls.sessionActive) {
        this.startNotificationPoll()
      } else {
        this.stopPolling()
      }
    },
    startNotificationPoll() {
      if (this.polls.notificationIntervalId) {
        this.stopPolling()
      }
      this.polls.isActive = true
      this.pollNotifications()
    },
    pollNotifications() {
      if (!this.polls.isActive) return
      // with node 16.x this can be cleaner with async/await
      getUserNotifications(this.showRead, this.lastTimestamp).then(
        ([err, newNotifications]) => {
          if (err) {
            if (err.response?.status && [401, 403].includes(err.response.status)) {
              this.onPollError(true)
            }
            this.onPollError()
            return
          }
          this.polls.retryCount = 0
          newNotifications.forEach(notif => {
            if (!this.notifications.find(x => x.id === notif.id)) {
              this.notifications.unshift(notif)
            }
          })
          this.polls.notificationIntervalId = setTimeout(this.pollNotifications, this.polls.pollTimeout)
        }
      )
    },
    onPollError(forceStop = false) {
      this.polls.retryCount++
      if (forceStop) {
        this.polls.sessionActive = false
      }
      if (forceStop || this.polls.retryCount > 4) {
        this.stopPolling(true)
        return
      }
      this.polls.notificationIntervalId = setTimeout(this.pollNotifications, this.polls.pollTimeout)
    },
    stopPolling(setErrorMessage = false) {
      if (this.polls.notificationIntervalId) {
        clearTimeout(this.polls.notificationIntervalId)
      }
      this.polls.isActive = false
      this.polls.notificationIntervalId = null
      this.polls.retryCount = 0
      if (setErrorMessage) {
        this.notificationMessage = "Notifications have stopped; reload to restart."
      }
    },
    updateNotification(id, updatedProperties) {
      let notificationIdx = this.notifications.findIndex((notification) => notification.id === id)
      if (updatedProperties.read_at && !this.showRead) {
        this.notifications.splice(notificationIdx, 1)
      } else {
        this.notifications[notificationIdx] = { ...this.notifications[notificationIdx], ...updatedProperties }
      }
    },
    selectNotification(id) {
      this.selectedNotification = id
    },
    loadNotifications() {
      getUserNotifications(this.showRead).then(
        ([err, notifications]) => {
          if (err) {
            let forceStop = err.status_code && [401, 403].includes(err.status_code)
            this.onPollError(forceStop)
            return
          }
          this.notifications = notifications
        }
      )
    }
  },
  beforeDestory() {
    document.removeEventListener("visibilitychange", this.visibilityChange, false)
  }
}
</script>
