import Cookies from 'js-cookie'
import { createRouter, createWebHistory } from 'vue-router'
import { updateUserNotificationById } from '../api/user'
import { useUserStore } from '../stores/user'
import { setDocumentTitlePage } from '../views/common'
import { getPreferenceValue } from './../components/preferences'

const Login = () => import('../views/Login.vue')

const Dashboard = () => import(/* webpackChunkName: "dashboard" */ '../views/All/Dashboard.vue')
const Bookmarks = () => import(/* webpackChunkName: "bookmarks" */ '../views/All/Bookmarks.vue')
const Build = () => import(/* webpackChunkName: "build" */ '../views/All/Build.vue')
const Diff = () => import(/* webpackChunkName: "diff" */ '../views/All/Diff.vue')
const Runs = () => import(/* webpackChunkName: "history" */ '../views/All/Runs.vue')
const Swagger = () => import(/* webpackChunkName: "swagger" */ '../views/All/Swagger.vue')
const Frontends = () => import(/* webpackChunkName: "frontends" */ '../views/All/Frontends.vue')

const Vault = () => import(/* webpackChunkName: "vault" */ '../views/Admin/Vault.vue')
const Migrate = () => import(/* webpackChunkName: "migrate" */ '../views/Admin/Migrate.vue')
const Log = () => import(/* webpackChunkName: "log" */ '../views/Admin/Log.vue')
const OpenVPN = () => import(/* webpackChunkName: "openvpn" */ '../views/Admin/OpenVPN.vue')

const ProfileContainer = () => import(/* webpackChunkName: "profile" */ '../views/All/ProfileContainer.vue')
const Preferences = () => import(/* webpackChunkName: "preferences" */ '../views/All/Preferences.vue')

const SignUp = () => import(/* webpackChunkName: "Signup" */ '../views/Admin/SignUp.vue')
const ResetPassword = () => import(/* webpackChunkName: "ResetPassword" */ '../views/All/ResetPassword.vue')
const InviteStepper = () => import(/* webpackChunkName: "InviteStepper" */ '../views/Admin/InviteStepper.vue')
const SchedulerView = () => import(/* webpackChunkName: "SchedulerView" */ '../components/schedule/SchedulerView.vue')
const ScheduleDashboard = () => import(/* webpackChunkName: "ScheduleDashboard" */ '../components/schedule/ScheduleDashboard.vue')

const CatalogView = () => import(/* webpackChunkName: "CatalogView" */ '../views/Admin/CatalogView.vue')

import { routes as etlRoutes } from "./etl_routes"


export const routes = [
  {
    path: '/',
    name: 'login',
    component: Login,
    props: true,
    meta: {
      title: 'Login'
    }
  },
  {
    path: "/catalog",
    name: "catalog",
    component: CatalogView,
    // props: true,
    meta: {
      title: 'Catalog',
      requiresAuth: true,
    }
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: Dashboard,
    meta: {
      title: "Dashboard",
      requiresAuth: true
    }
  },
  {
    path: '/bookmarks',
    name: 'bookmarks',
    component: Bookmarks,
    meta: {
      title: 'Bookmarks',
      requiresAuth: true
    }
  },
  {
    path: '/build',
    name: 'build',
    component: Build,
    meta: {
      title: 'Build',
      requiresAuth: true
    }
  },
  {
    path: '/diff',
    name: 'diff',
    component: Diff,
    meta: {
      title: 'Diff',
      requiresAuth: true,
      requiresStaffAdmin: true
    }
  },
  {
    path: '/history',
    name: 'history',
    component: Runs,
    meta: {
      title: 'Run History',
      requiresAuth: true
    }
  },
  {
    path: '/swagger',
    name: 'swagger',
    component: Swagger,
    meta: {
      title: 'Swagger API',
      requiresAuth: true
    }
  },
  {
    path: '/frontends',
    name: 'frontends',
    component: Frontends,
    meta: {
      title: 'Frontends',
      requiresAuth: true,
      requiresPermissions: ["view_frontend"],
    },
  },
  {
    path: '/vault',
    name: 'vault',
    component: Vault,
    meta: {
      title: 'Vault',
      requiresVault: true,
      requiresAuth: true
    }
  },
  {
    path: '/migrate',
    name: 'migrate',
    component: Migrate,
    meta: {
      title: 'Migrate',
      requiresAuth: true,
      requiresStaffAdmin: true
    }
  },
  {
    path: '/log',
    name: 'log',
    component: Log,
    meta: {
      title: 'Log',
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/openvpn',
    name: 'openvpn',
    component: OpenVPN,
    meta: {
      title: 'OpenVPN',
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/preferences',
    name: 'preferences',
    component: Preferences,
    meta: {
      title: 'Preferences',
      requiresAuth: true,
    }
  },
  {
    path: '/profile',
    component: ProfileContainer,
    meta: {
      title: 'Profile',
      requiresAuth: true,
    }
  },
  {
    path: '/signup',
    name: 'signup',
    component: SignUp,
    meta: {
      title: 'Signup',
      requiresAuth: false,
    }
  },
  {
    path: '/reset-password',
    name: 'passwordreset',
    component: ResetPassword,
    meta: {
      title: 'Reset Password',
      requiresAuth: false,
    }
  },
  {
    path: '/invite',
    name: 'invite',
    component: InviteStepper,
    meta: {
      title: 'Invite',
      requiresAuth: true,
      requiresStaffAdmin: true
    }
  },
  {
    path: '/schedules/dashboard',
    name: 'schedule',
    component: ScheduleDashboard,
    meta: {
      requiresAuth: true,
      requiresAdmin: false
    }
  },
  {
    path: '/schedules/:scheduleId(\\d+)?',
    name: 'schedule-edit',
    component: SchedulerView,
    meta: {
      requiresAuth: true,
      requiresAdmin: false
    }
  },
  ...etlRoutes,
  {
    path: '/:pathMatch(.*)*',
    name: "not-found",
    redirect: { name: 'login' }
  }
]


const router = () => {
  const vue_router = createRouter({
    history: createWebHistory(),
    base: "/",
    routes: routes,
    meta: {
      title: 'GLYUE',
      metaTags: []
    },
  })

  vue_router.beforeEach(async (to, from, next) => {
    const store = useUserStore()
    const [ , data ] = await store.checkUserAuth()
    let landing = getPreferenceValue("landing-page")
    /// Notification query params are replaced in Build.vue
    if (to.query.notif && data?.connected) {
      let notification = to.query.notif
      updateUserNotificationById(notification, { "isRead": true })
    }

    const cookieURL = Cookies.get("after-login-redirect")
    if (cookieURL) {
      Cookies.remove("after-login-redirect")
    }
    const toQueryNext = to.query.nextPath || to.query.next
    const fromQueryNext = from.query.nextPath || from.query.next

    // const canUseEtl = data.model_permissions?.["can_use_etl"]
    const canUseEtl = data.default_experience === "etl"

    if (data.connected && cookieURL) {
      try {
        let url = new URL(cookieURL, window.location)
        if (url.origin === window.location.origin) {
          next(false)
          window.location.href = url
        } else {
          next({ name: landing })
        }
      } catch (e) {
        console.error(e)
        console.debug({ cookieURL })
        next({ name: landing })
      }
    }
    else if (!data.connected && cookieURL) {
      next({ name: 'login', query: { nextPath: cookieURL } })
    }
    else if (to.name === "not-found" && !data.connected) {
      Cookies.set("after-login-redirect", to.fullPath, { expires: 1 })
      next({ name: 'login', query: { nextPath: to.fullPath } })
    }
    else if (data.connected && to.name === "login" && toQueryNext) {
      try {
        let url = new URL(toQueryNext, window.location)
        if (url.origin === window.location.origin) {
          next(false)
          window.location.href = url
        } else {
          next({ name: landing })
        }
      } catch (e) {
        console.error(e)
        console.debug({ toQueryNext })
        next({ name: landing })
      }
    }
    else if (data.connected && from.name === "login" && fromQueryNext) {
      try {
        let url = new URL(fromQueryNext, window.location)
        if (url.origin === window.location.origin) {
          next(false)
          window.location.href = url
        } else {
          next({ name: landing })
        }
      } catch (e) {
        console.debug({ fromQueryNext })
        console.error(e)
        next({ name: landing })
      }
    }
    // No need to check `meta` for all matched routes
    // See: https://router.vuejs.org/guide/advanced/meta#Route-Meta-Fields
    else if (!data.connected && to.meta.requiresAuth) {
      next({ name: 'login', query: { nextPath: to.fullPath } })
    }
    else if (data.connected && to.meta.requiresEtlView && !canUseEtl) {
      next({ name: landing })
    }
    else if (data.connected && !to.meta.requiresEtlView && canUseEtl) {
      next({ name: "workflows" })
    }
    else if (to.meta.requiresAdmin && !data.admin) {
      next({ name: landing })
    }
    else if (to.meta.requiresStaffAdmin && !(data.staff || data.admin)) {
      next({ name: landing })
    }
    else if (to.meta.requiresVault && !data.vault) {
      next({ name: landing })
    }
    else if (
      to.meta.requiresPermissions?.length > 0
      && to.meta.requiresPermissions.some((permission) => !store.model_permissions.has(permission))
    ) {
      next({ name: landing })
    }
    else if (data.connected && to.name === 'login') {
      next({ name: landing })
    }
    else {
      next()
    }
  })

  vue_router.afterEach((to) => {
    const store = useUserStore()
    setDocumentTitlePage(to, store.name)
  })

  return vue_router
}

export default router
