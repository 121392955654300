import "@mdi/font/css/materialdesignicons.css"
import "vuetify/styles"
import { createVuetify } from "vuetify"
import LuxonAdapter from "@date-io/luxon"

export const vuetifyOptions = {
  ssr: true,
  theme: {
    defaultTheme: "light",
    themes: {
      light: {
        dark: false,
        colors: {
          primary: "#02b8a6", // Sandbox Banking Logo Green
          secondary: "#424242",
          accent: "#82B1FF",
          error: "#FF5252",
          info: "#2196F3",
          success: "#4CAF50",
          warning: "#FFC107",
          sbcyan: "#028090",
          sbgreen: "#00CCB8",
          sbred: "#FE5F75",
          sbyellow: "#FF9A44",
          "etl-blue": "#98C1D9",
        }
      },
      dark: {
        dark: true,
        colors: {
          primary: "#02b8a6", // Sandbox Banking Logo Green
          secondary: "#424242",
          accent: "#82B1FF",
          error: "#FF5252",
          info: "#2196F3",
          success: "#4CAF50",
          warning: "#FFC107",
          sbcyan: "#028090",
          sbgreen: "#00CCB8",
          sbred: "#FE5F75",
          sbyellow: "#FF9A44",
        },
      },
      etl_light: {
        colors: {
          background: "#000000",
          primary: "#4093A5",
          secondary: "#424242",
          accent: "#82B1FF",
          error: "#FF5252",
          info: "#2196F3",
          success: "#4093A5",
          // success: "#00CCB8",
          warning: "#FFC107",
        }
      }
    },
  },
  date: {
    adapter: new LuxonAdapter({ locale: "en" }),
  },
}

export default createVuetify(vuetifyOptions)
