<template>
  <v-list>
    <v-list-item v-if="searching">
      Searching suppport documentation...
    </v-list-item>
    <v-list-item
      v-else-if="status !== 'complete'"
      class="d-flex justify-space-between"
      target="blank"
      href="https://alphapack.atlassian.net/wiki/spaces/PUB"
    >
      <span>Search Support Documentation for system related information</span>
      <v-icon>mdi-open-in-new</v-icon>
    </v-list-item>
    <v-list
      v-if="formattedAtlassianResults.length > 0"
      lines="three"
    >
      <v-list-item
        v-for="searchResult in formattedAtlassianResults"
        :key="searchResult.id"
        target="_blank"
        :href="`https://alphapack.atlassian.net/wiki${searchResult.url}`"
      >
        <v-list-item-title>
          {{ searchResult.title }} <v-icon size="small">
            mdi-open-in-new
          </v-icon>
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ searchResult.excerpt }}
        </v-list-item-subtitle>
      </v-list-item>
    </v-list>
    <v-list-item v-else-if="status === 'complete' && search">
      No results
    </v-list-item>
  </v-list>
</template>

<script>
import { getSearchDocumentation } from "../../api/documentation"
import debounce from "debounce"
export default {
  expose: [ 'cancelSearch' ],
  props: {
    search: {
      type: [String, null],
      required: true
    },
    searching: {
      type: Boolean,
      required: true,
    },
    status: {
      type: String,
      required: true
    }
  },
  emits: ["update:status", "update:error", "update:results"],
  data() {
    return {
      atlassianResults: [],
      page: null,
      limit: null,
      lastSearch: ""
    }
  },
  computed: {
    formattedAtlassianResults: function () {
      return this.atlassianResults.map((result) => {
        return {
          id: result.id,
          title: this.removeAtlassianFormatting(result.title),
          excerpt: this.removeAtlassianFormatting(result.excerpt),
          url: result.url,
        }
      })
    },
  },
  watch: {
    search: {
      handler: function () {
        this.searchAtlassian()
      }
    },
  },
  activated() {
    if (this.search && this.lastSearch !== this.search) {
      this.searchAtlassian()
    }
  },
  methods: {
    searchAtlassian: debounce(async function () {
      this.cancelSearch()
      if (!this.search) {
        this.$emit("update:status", "idle")
        this.$emit("update:error", "")
        return
      }
      this.controller = new AbortController()
      this.$emit("update:status", "searching")
      this.$metrics.enterText('app/docsearch/atlassian', this.search)
      const [err, data] = await getSearchDocumentation(this.search, {
        params: {
          page: this.page,
          limit: this.limit,
          type: 'atlassian',
        },
        signal: this.controller.signal
      })

      if (err) {
        this.$emit("update:error", err.error ?? err)
        return
      }

      this.$emit("update:status", "complete")
      this.atlassianResults = data.results
      this.lastSearch = this.search
      this.$emit("update:results", this.atlassianResults.length > 0)
    }, 500),
    cancelSearch() {
      if (this.controller) {
        this.controller.abort()
        this.controller = null
      }
      this.atlassianResults = []
    },
    removeAtlassianFormatting(string) {
      let pattern = /@@@(.*?)@@@/g;
      let matches = string.match(pattern);
      if (matches) {
        for (let match of matches) {
          string = string.replace(match, '');
        }
      }
      return string;
    }
  },
}

</script>
