const WorkflowDashboard = () => import(/* webpackChunkName: "WorkflowDashboard" */ "../etl/WorkflowDashboard.vue")
const WorkflowForm = () => import(/* webpackChunkName: "WorkflowForm" */ "../etl/WorkflowForm.vue")
const ConnectionDashboard = () => import(/* webpackChunkName: "ConnectionDashboard" */ "../etl/ConnectionDashboard.vue")
const ConnectionForm = () => import(/* webpackChunkName: "ConnectionForm" */ "../etl/ConnectionForm.vue")
const TransformationBuilder = () => import(/* webpackChunkName: "TransformationBuilder" */ "../etl/TransformationBuilder.vue")
const InviteStepper = () => import(/* webpackChunkName: "InviteStepper" */ "../views/Admin/InviteStepper.vue")

const etlMeta = {
  requiresAuth: true,
  requiresAdmin: false,
  requiresEtlView: true
}

export const routes = [
  {
    path: "/etl/workflows",
    name: "workflows",
    component: WorkflowDashboard,
    meta: etlMeta,
  },
  {
    path: "/etl/workflows/create",
    name: "workflow-create",
    component: WorkflowForm,
    meta: etlMeta,
  },
  {
    path: "/etl/workflows/:workflowId(\\d+)",
    name: "workflow-edit",
    component: WorkflowForm,
    meta: etlMeta,
  },
  {
    path: "/etl/workflows/:workflowId(\\d+)/transformations",
    name: "workflow-transformations",
    component: TransformationBuilder,
    meta: etlMeta,
  },
  {
    path: "/etl/connections",
    name: "connections",
    component: ConnectionDashboard,
    meta: etlMeta,
  },
  {
    path: "/etl/connections/create",
    name: "connection-create",
    component: ConnectionForm,
    meta: etlMeta,
  },
  {
    path: "/etl/connections/:configId(\\d+)",
    name: "connection-edit",
    component: ConnectionForm,
    meta: etlMeta,
  },
  // {
  //   path: '/etl/valuemaps',
  //   name: 'valuemap-edit',
  //   component: () => import("../etl/EtlValueMapping.vue"),
  //   meta: etlMeta,
  // },
  {
    path: "/etl/invite",
    name: "etl-invite",
    component: InviteStepper,
    meta: {
      ...etlMeta,
      requiresStaffAdmin: true
    },
  },
]
