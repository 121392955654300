<template>
  <!-- Glyue App Bar -->
  <v-app-bar
    v-if="!isEtl"
    :color="installationStore.app_color"
    elevation="0"
    border
    class="justify-space-between"
  >
    <v-app-bar-title>
      <template v-if="installationStore.logo_src">
        <router-link to="/">
          <img
            alt="Uploaded Logo"
            :src="installationStore.logo_src"
            style="max-height:50px;max-width:175px"
          />
        </router-link>
        <v-icon style="height:30px;padding-bottom:30px;padding-left:20px;padding-right:20px">
          mdi-plus
        </v-icon>
      </template>
      <router-link to="/">
        <img
          alt="Sandbox Logo"
          src="../../media/sandbox_banking_logo.png"
          style="max-height:50px;max-width:175px"
        />
      </router-link>
    </v-app-bar-title>
    <div
      v-if="userStore.connected"
      class="d-flex justify-end"
    >
      <search-documentation-menu
        v-if="smAndUp"
        :width="mdAndUp ? '500px' : '320px'"
      />
      <g-btn
        icon
        color="grey"
        metric="app/glyue-help-center"
        target="_blank"
        :href="installationStore.help_center_url"
        tooltip="Help Center"
      >
        <v-icon>mdi-help-circle</v-icon>
      </g-btn>
      <feedback-dialog
        :jira_enabled="installationStore.jira_enabled"
        :help_center_url="installationStore.help_center_url"
      />
      <notification-menu />
      <account-menu
        :admin="userStore.admin"
        :staff="userStore.staff"
        :git="installationStore.git"
      />
    </div>
  </v-app-bar>

  <!-- ETL App BAR -->
  <v-app-bar
    v-else
    elevation="0"
  >
    <template #prepend>
      <div class="pa-2 pl-1">
        <v-img
          :width="180"
          src="@/media/sandbox_banking_logo.png"
        />
      </div>
    </template>
    <template #title>
      <span class="text-h5 font-weight-bold">GlyuETL</span>
    </template>
  </v-app-bar>
</template>

<script setup>
import { computed } from "vue"
import { useInstallationStore } from "../../stores/installation"
import { useUserStore } from "../../stores/user"
import AccountMenu from "./AccountMenu.vue";
import FeedbackDialog from "./FeedbackDialog.vue";
import NotificationMenu from "./NotificationMenu.vue";
import SearchDocumentationMenu from "./SearchDocumentationMenu.vue";
import { useDisplay } from "vuetify";
import { useRoute } from "vue-router";

const { mdAndUp, smAndUp } = useDisplay()
const installationStore = useInstallationStore()
const userStore = useUserStore()
const route = useRoute()

const isEtl = computed(() => route.fullPath.startsWith("/etl"))
</script>
